import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import SwipeableCard from "../compo/landingPage";
import LoginModal from "../auth/logIn";
import SignUpModal from "../auth/signUp";
import ResetPasswordModal from "../auth/resetPassword";
import ContactForm from "../compo/contact";
import ProductPage from "../compo/productDetails";
import Product from "../compo/product";
import TousLesObjets from "../compo/tousLesObjets";
import NewArrivals from "../compo/NewArrivals";


function Router() {
  return (
    <Routes>
      <Route path="/signup" element={<SignUpModal />} />
      <Route path="/reset" element={<ResetPasswordModal />} />

      <Route path="/" element={<SwipeableCard />} />
      <Route path="/contact" element={<ContactForm />} />
      <Route path="/details/:productId" element={<ProductPage />} />
      <Route path="/checkout" element={<Product />} />
      <Route path="/tousLesObjets" element={<TousLesObjets />} />
    </Routes>
  );
}

export default Router;
