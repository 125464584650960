import React, { useEffect, useState } from 'react';
import { Grid, Box, Button, Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchObjets } from '../store/objet';
import ObjetCard from './ObjetCard';

const ITEMS_PER_PAGE = 5; // Number of items per page

const TousLesObjets = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const objets = useSelector((state) => state.objet.items);

  const [currentPage, setCurrentPage] = useState(1);

  const searchParams = new URLSearchParams(location.search);
  const categoryId = searchParams.get('category');

  useEffect(() => {
    if (categoryId) {
      dispatch(fetchObjets(categoryId));
    } else {
      dispatch(fetchObjets());
    }
  }, [categoryId, dispatch]);

  // Calculate total pages
  const totalPages = Math.ceil(objets.length / ITEMS_PER_PAGE);

  // Get current page items
  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const currentItems = objets.slice(startIndex, startIndex + ITEMS_PER_PAGE);

  // Handle page navigation
  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  return (
    <Box sx={{ backgroundColor: "#232323", padding: 3, minHeight: "100vh" }}>
      <Grid container spacing={2} justifyContent="center"  sx={{ mt:8}}>
        {currentItems.length > 0 ? (
          currentItems.map((objet) => (
            <Grid item xs={12} sm={6} md={4} lg={2.4} key={objet.id}>
              <ObjetCard objet={objet} />
            </Grid>
          ))
        ) : (
          <Typography variant="body1" sx={{ color: "#A68E5D" }}>
            Aucun objet trouvé pour cette catégorie.
          </Typography>
        )}
      </Grid>

      {/* Pagination Controls - Moved Down */}
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 20 }}>
  <Button
    onClick={handlePreviousPage}
    sx={{
      color: "#fff",
      fontWeight: "bold",
      cursor: "pointer",
      "&:hover": { backgroundColor: "rgba(255, 0, 0, 0.1)" }
    }}
  >
    Précédent
  </Button>

  <Typography variant="body1" sx={{ mx: 2, color: "#A68E5D", fontWeight: "bold" }}>
    Page {currentPage} / {totalPages}
  </Typography>

  <Button
    onClick={handleNextPage}
    sx={{
      color: "#fff",
      fontWeight: "bold",
      cursor:  "pointer",
      "&:hover": { backgroundColor: "rgba(255,255,255,0.1)" }
    }}
  >
    Suivant
  </Button>

      </Box>
    </Box>
  );
};

export default TousLesObjets;
