import React, { useState } from "react";
import {
  useMediaQuery,
  useTheme,
  Box,
  TextField,
  Typography,
  IconButton,
  Grid,
} from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from '@mui/icons-material/Phone';
import MuiPhoneNumber from "material-ui-phone-number";
import { useForm, Controller } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { ContactSchema } from "./validation-schema";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import { useDispatch, useSelector } from "react-redux";
import { sendContactUsEmail } from "../store/mailer";

const ContactForm = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const isBrowser = useMediaQuery(theme.breakpoints.up("md"));
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();


  const handleBackClick = () => {
    navigate(-1);
  };

  const [isSubmitted, setIsSubmitted] = useState(false);

  const { register, control, handleSubmit, reset, formState: { errors } } = useForm({
    resolver: zodResolver(ContactSchema),
  });
  
  const onSubmit = (data) => {
    setLoading(true);
    const emailData = {
      clientName: data.fullName,
      clientEmail: data.email,
      clientPhoneNumber: data.phoneNumber,
      clientMessage: data.message,
      from: data.email, 
    };
  
    dispatch(sendContactUsEmail(emailData))
      .unwrap()
      .then(() => {
        setLoading(false);
        reset(); 
        setIsSubmitted(true);
        setTimeout(() => setIsSubmitted(false), 10000);
      })
      .catch(() => setLoading(false));
  };
  

  const CustomLoadingButton = ({ sx, variant = "contained", color = "secondary", ...props }) => (
    <LoadingButton
      {...props}
      variant={variant}
      color={color}
      sx={{
        ...sx,
        color: "black",
        "& .MuiCircularProgress-root": {
          color: "white",
        },
      }}
    >
      {props.children}
    </LoadingButton>
  );

  return (
    <Box
      sx={{
        backgroundColor: "#232323",
        color: "#333",
        paddingBottom: { xs: "2rem", md: "8rem" },
        paddingTop: { xs: "1rem", md: "2rem" },
        paddingLeft: { xs: "1rem", md: "3rem" },
        paddingRight: { xs: "1rem", md: "3rem" },
      }}
    >
      <Grid container spacing={4} mt={5}>
        <Grid item xs={12} md={5} sx={{ ml: { xs: "0", md: "5rem" }, px: { xs: 2, md: 0 } }}>
          <IconButton onClick={handleBackClick} sx={{ display: "flex", alignItems: "center" }}>
            <ArrowBackIcon sx={{ color: "#A68E5D" }} />
            <Typography sx={{ color: "#A68E5D", ml: 1 }}>RETOUR</Typography>
          </IconButton>
          <Box sx={{ mb: { xs: 4, md: 6 } }}>
            <Typography variant="h4" color="#A68E5D" align="left" marginTop={2} gutterBottom>
              Contactez-nous
            </Typography>
            <Typography variant="body1" color="white" align="left" paragraph>
              Pour toute demande d&apos;informations ou d&apos;assistance commerciale,<br /> veuillez nous contacter en utilisant le formulaire ci-dessous.
            </Typography>
            <Box sx={{ mt: 2 }}>
              <Grid container spacing={2} flexDirection="column">
                <Grid item xs={12} md={10}>
                  <Box
                    sx={{
                      border: "1px solid grey",
                      width: { xs: "90%", md: "80%" },
                      padding: "1rem",
                      backgroundColor: "#484848",
                      color: "white",
                      borderRadius: "40px",
                      display: "flex",
                      alignItems: "center",
                      mb: 2,
                    }}
                  >
                    <Box
                      sx={{
                        backgroundColor: "#232323",
                        borderRadius: "50%",
                        padding: "1rem",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginRight: 2,
                      }}
                    >
                      <EmailIcon sx={{ color: "white" }} />
                    </Box>
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                   
                      <Typography
                        component="a"
                        href="mailto:artentrenous@gmail.com"
                        variant="h6"
                        sx={{
                          color: "white",
                          textDecoration: "none",
                          "&:hover": { textDecoration: "underline" },
                          fontSize: { xs: "0.875rem", sm: "1rem" }, // Plus petit sur mobile (xs) et plus grand sur des écrans plus larges (sm)
                        }}
                      >
                        artentrenous@gmail.com
                      </Typography>

                    </Box>
                  </Box>
                </Grid>

              
              </Grid>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} md={6} mt={isBrowser ? 5 : 0}>
          <Box
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            sx={{
              backgroundColor: "#232323",
              padding: "2rem",
              borderRadius: "12px",
              boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
              border: "1px solid grey",
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Votre nom complet"
                  variant="outlined"
                  fullWidth
                  {...register("fullName")}
                  error={!!errors.fullName}
                  helperText={errors.fullName?.message}
                  InputLabelProps={{ style: { color: "#888" } }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      color: "#FFFFFF",
                      "& fieldset": {
                        borderColor: "#ddd",
                      },
                    },
                    "& .MuiInputLabel-root": {
                      color: "#888",
                    },
                    "&:hover .MuiOutlinedInput-root fieldset": {
                      borderColor: "#A68E5D",
                    },
                  }}
                />

              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Email professionnel"
                  variant="outlined"
                  fullWidth
                  {...register("email")}
                  error={!!errors.email}
                  helperText={errors.email?.message}
                  InputLabelProps={{ style: { color: "#888" } }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      color: "#FFFFFF",
                      "& fieldset": {
                        borderColor: "#ddd",
                      },
                    },
                    "& .MuiInputLabel-root": {
                      color: "#888",
                    },
                    "&:hover .MuiOutlinedInput-root fieldset": {
                      borderColor: "#A68E5D",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="phoneNumber"
                  control={control}
                  render={({ field }) => (
                    <MuiPhoneNumber
                      {...field}
                      defaultCountry="fr"
                      onlyCountries={["fr", "ch", "lu", "li", "be"]}
                      label="Numéro de téléphone"
                      variant="outlined"
                      fullWidth
                      error={!!errors.phoneNumber}
                      helperText={errors.phoneNumber?.message}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          color: "#FFFFFF",
                          "& fieldset": {
                            borderColor: "#ddd",
                          },
                        },
                        "& .MuiInputLabel-root": {
                          color: "#888",
                        },
                        "&:hover .MuiOutlinedInput-root fieldset": {
                          borderColor: "#A68E5D",
                        },
                      }}
                      InputLabelProps={{ style: { color: "#888" } }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Your message"
                  variant="outlined"
                  multiline
                  rows={4}
                  fullWidth
                  {...register("message")}
                  error={!!errors.message}
                  helperText={errors.message?.message}
                  InputLabelProps={{ style: { color: "#888" } }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      color: "#FFFFFF",
                      "& fieldset": {
                        borderColor: "#ddd",
                      },
                    },
                    "& .MuiInputLabel-root": {
                      color: "#888",
                    },
                    "&:hover .MuiOutlinedInput-root fieldset": {
                      borderColor: "#A68E5D",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <CustomLoadingButton
                  loading={loading}
                  type="submit"
                  sx={{
                    backgroundColor: "#A68E5D",
                    color: "white",
                    borderRadius: "20px",
                    "&:hover": { backgroundColor: "#D1B478" },
                    width: "100%",
                  }}
                >
                  Envoyer
                </CustomLoadingButton>
                {isSubmitted && (
  <Typography variant="body2" color="#A68E5D" sx={{ mt: 1, textAlign: "center" }}>
    Votre message a été envoyé avec succès.
  </Typography>
)}

              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ContactForm;
