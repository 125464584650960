import './App.css';
import Navbar from './navbar/navbar';
import Footer from './footer/footer';
import Router from './router/router';
import { BrowserRouter } from 'react-router-dom'; // Import BrowserRouter

function App() {
  return (
      <div className="App">
        <Navbar />
        <Router />
      </div>
  );
}

export default App;
