import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import client from "../configs/sanityClient";

export const fetchUtilisateurs = createAsyncThunk("utilisateur/fetchUtilisateurs", async () => {
  const query = `*[_type == "utilisateur"]{
    _id,
    prenom,
    nom,
    email,
    adresse,
    numeroTelephone,
    "pays": pays->{
      _id,
      nom
    },
    isAdmin,
    confirmation,
    dbId
  }`;
  const utilisateursData = await client.fetch(query);
  return utilisateursData;
});

export const fetchUtilisateurById = createAsyncThunk("utilisateur/fetchUtilisateurById", async (id) => {
  const utilisateurData = await client.fetch(`*[_type == "utilisateur" && _id == $id]{
    _id,
    prenom,
    nom,
    email,
    adresse,
    numeroTelephone,
    "pays": pays->{
      _id,
      nom
    },
    isAdmin,
    confirmation,
    dbId
  }[0]`, { id });

  return utilisateurData;
});

const utilisateurSlice = createSlice({
  name: "utilisateur",
  initialState: {
    items: [],
    selectedItem: null,
    status: "idle",
    error: null,
    isSignUpModalOpen:false,
    isLoginModalOpen:false,
  },

  reducers:{
setSignUpModal:(state , action)=>{
  state.isSignUpModalOpen=action.payload
},
setSignInModal:(state , action)=>{
  state.isLoginModalOpen=action.payload
}
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUtilisateurs.fulfilled, (state, action) => {
        state.items = action.payload;
        state.status = "succeeded";
      })
      .addCase(fetchUtilisateurs.rejected, (state, action) => {
        state.error = action.error.message;
        state.status = "failed";
      })
      .addCase(fetchUtilisateurById.fulfilled, (state, action) => {
        state.selectedItem = action.payload;
        state.status = "succeeded";
      })
      .addCase(fetchUtilisateurById.rejected, (state, action) => {
        state.error = action.error.message;
        state.status = "failed";
      });
  },
});
export const {setSignUpModal, setSignInModal} = utilisateurSlice.actions
export default utilisateurSlice.reducer;
