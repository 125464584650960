import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import client from "../configs/sanityClient";

export const fetchPays = createAsyncThunk("pays/fetchPays", async () => {
  const paysData = await client.fetch(`*[_type == "pays"]{
 _id,
  nom,
  dbId,
  "image": image[]->{path, alt, type},
"utilisateurs": *[_type == "utilisateur" && references(^._id)],
"objet": *[_type == "objet" && references(^._id)]
          }`);
  return paysData;
});

const paysSlice = createSlice({
  name: "pays",
  initialState: {
    items: [],
    status: "idle",
    error: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPays.fulfilled, (state, action) => {
        state.items = action.payload;
        state.status = "succeeded";
      })
      .addCase(fetchPays.rejected, (state, action) => {
        state.error = action.error.message;
      });
  },
});

export default paysSlice.reducer;
