import categoriesReducer from "./categories";
import { configureStore } from '@reduxjs/toolkit';
import paysReducer from "./pays";
import objetReducer from "./objet";
import utilisateurSlice from "./utilisateur";
import authReducer from "./auth";

export const store = configureStore({
    reducer: {
      pays: paysReducer,
      categories: categoriesReducer,
      objet: objetReducer,
      utilisateur: utilisateurSlice,
      auth: authReducer,
    },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
      }),
  });
