import React from 'react';
import { Card, Typography, Box } from '@mui/material';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { useNavigate } from 'react-router-dom';
import { urlFor } from '../configs/sanityClient';


const AutoPlaySwipeableViews = autoPlay(SwipeableViews);
const constantImageUrl = 'https://static.vecteezy.com/system/resources/thumbnails/035/147/887/small/ai-generated-antique-armchair-throne-on-transparent-background-image-png.png';

const ObjetCard = ({ objet }) => {

  const navigate = useNavigate();

  const handleCardClick = () => {
    navigate(`/details/${objet.id || objet._id}`); // Ensure the correct ID is used
  };

  const getImageUrl = (image) => {
    try {
      const assetRef = image.file?.asset?._ref;
      if (assetRef) {
        return urlFor(image.file).url();
      }
    } catch (error) {
      console.error('Error building Sanity image URL:', error);
    }
    return image?.path || constantImageUrl;
  };

  return (
    <Box
      onClick={handleCardClick}
      sx={{
        backgroundColor: "#232323",
        color: "#A68E5D",
        textAlign: "center",
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        cursor: 'pointer',
        maxWidth: 200,
        margin: '0 auto',
        transition: 'transform 0.3s ease, box-shadow 0.3s ease',
        '&:hover': {
          transform: 'scale(1.05)',
          boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.3)',
        }
      }}
    >
      {/* Image Carousel */}
      <AutoPlaySwipeableViews enableMouseEvents={false}>
        {objet.images && objet.images.length > 0 ? (
          objet.images.map((image, i) => (
            <>
              {image?.path ?
                <img
                  key={i}
                  src={image.path}
                  alt={`Objet Image ${i}`}
                  style={{
                    width: "100%",
                    height: "200px",
                    objectFit: "contain",
                    borderTopLeftRadius: "4px",
                    borderTopRightRadius: "4px",
                    margin: "0",
                    display: "block",
                  }}
                />
                :
                <img
                  key={i}
                  src={getImageUrl(image)}
                  alt={`Objet Image ${i}`}
                  style={{
                    width: "100%",
                    height: "200px",
                    objectFit: "contain",
                    borderTopLeftRadius: "4px",
                    borderTopRightRadius: "4px",
                    margin: "0",
                    display: "block",
                  }}
                />
              }


            </>
          ))
        ) : (
          <img
            src={constantImageUrl}
            alt="Default Image"
            style={{
              width: "100%",
              height: "200px",
              objectFit: "contain",
              borderTopLeftRadius: "4px",
              borderTopRightRadius: "4px",
              margin: "0",
              display: "block",
            }}
          />
        )}
      </AutoPlaySwipeableViews>

      {/* Information Section */}
      <Box
        sx={{
          backgroundColor: "#232323",
          borderBottomLeftRadius: "4px",
          borderBottomRightRadius: "4px",
          textAlign: "left",
          padding: '10px',
        }}
      >
        <Typography variant="subtitle1" sx={{ color: "#FFFFFF" }}>
          {objet?.nom}
        </Typography>
        <Typography variant="caption" sx={{ color: "grey" }}>
          {objet?.categorie?.nom}
        </Typography>

        {/* Location and Price */}
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "8px" }}>
          <Typography variant="subtitle2" sx={{ color: "grey", display: "flex", alignItems: "center" }}>
            <LocationOnIcon fontSize="small" sx={{ verticalAlign: "middle", mr: 0.5 }} />
            {objet?.pays?.nom || 'Unknown Location'}
          </Typography>
          <Typography sx={{ color: "white", fontWeight: "bold" }}>
            {objet?.prix}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default ObjetCard;
