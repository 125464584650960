import React, { useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  IconButton,
  InputAdornment,
  FormControl,
} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import { changePassword } from '../store/auth'; 
import { toast } from 'react-toastify'; 

const SetNewPasswordModal = ({ onClose }) => {
  const dispatch = useDispatch();
  const email = useSelector((state) => state.auth.userEmailData?.email); // Get email from Redux store
  const [open, setOpen] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const handleClose = () => {
    setOpen(false);
    if (onClose) onClose();
  };

  const handleSavePassword = () => {
    if (password !== confirmPassword) {
      toast.error('Les mots de passe ne correspondent pas !');
      return;
    }

    // Dispatch change password action
    dispatch(changePassword({ email, password, confirmPassword }))
      .unwrap()
      .then(() => {
        toast.success('Mot de passe changé avec succès !', {
          position: 'top-right',
          autoClose: 3000,
          theme: 'dark',
        }); 
        handleClose();
      })
      .catch((error) => {
        toast.error(error, {
          position: 'top-right',
          autoClose: 3000,
          theme: 'dark',
        }); // Show the error toast
      });
  };

  return (
    <Box sx={{ textAlign: 'center' }}>
      <Dialog
        open={open}
        onClose={handleClose}
        sx={{
          '& .MuiDialog-paper': {
            backgroundColor: '#3c3c3c',
            color: '#A68E5D',
            borderRadius: 2,
            maxWidth: '400px',
            width: '100%',
            padding: '20px',
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)',
            textAlign: 'center',
            position: 'relative',
          },
          '& .MuiBackdrop-root': {
            backdropFilter: 'blur(4px)',
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
          },
        }}
      >
        <IconButton
          onClick={handleClose}
          sx={{
            position: 'absolute',
            top: 10,
            right: 10,
            color: '#A68E5D',
          }}
        >
          <CloseIcon />
        </IconButton>

        <DialogTitle
          sx={{
            color: '#A68E5D',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            fontSize: '1.5rem',
            textAlign: 'center',
            paddingBottom: 0,
          }}
        >
          Set New Password
        </DialogTitle>

        <DialogContent>
          <FormControl fullWidth variant="outlined" margin="normal">
            <TextField
              label="New Password"
              type={showPassword ? 'text' : 'password'}
              fullWidth
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                      sx={{ color: 'white' }}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
                style: { backgroundColor: 'transparent', color: 'white' },
              }}
              InputLabelProps={{
                style: { color: 'white' },
              }}
              sx={{
                mb: 2,
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: 'white',
                  },
                  '&:hover fieldset': {
                    borderColor: 'white',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: 'white',
                  },
                },
                '& .MuiInputLabel-outlined.Mui-focused': {
                  color: 'white',
                },
              }}
            />
          </FormControl>

          <FormControl fullWidth variant="outlined" margin="normal">
            <TextField
              label="Confirm Password"
              type={showConfirmPassword ? 'text' : 'password'}
              fullWidth
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                      edge="end"
                      sx={{ color: 'white' }}
                    >
                      {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
                style: { backgroundColor: 'transparent', color: 'white' },
              }}
              InputLabelProps={{
                style: { color: 'white' },
              }}
              sx={{
                mb: 2,
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: 'white',
                  },
                  '&:hover fieldset': {
                    borderColor: 'white',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: 'white',
                  },
                },
                '& .MuiInputLabel-outlined.Mui-focused': {
                  color: 'white',
                },
              }}
            />
          </FormControl>

          <Button
            variant="contained"
            fullWidth
            onClick={handleSavePassword}
            sx={{
              backgroundColor: 'grey',
              color: 'white',
              mt: 2,
              padding: '10px',
              fontSize: '1rem',
              '&:hover': {
                backgroundColor: 'transparent',
              },
            }}
          >
            Save Password
          </Button>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default SetNewPasswordModal;