import React, { useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
} from '@mui/material';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import VerificationCodeModal from './VerificationCodeModal';
import { useDispatch } from 'react-redux';
import { verifyEmail, sendVerificationCode } from '../store/auth'; // Import actions

const ResetPasswordModal = ({ onClose }) => {
  const [open, setOpen] = useState(true);
  const [verificationOpen, setVerificationOpen] = useState(false);
  const [email, setEmail] = useState('');
  const dispatch = useDispatch(); // Get dispatch function

  const handleClose = () => {
    setOpen(false);
    if (onClose) onClose();
  };

  const handleVerifyEmail = async () => {
    try {
      // Dispatch the verifyEmail action
      const resultAction = await dispatch(verifyEmail(email));


      if (verifyEmail.fulfilled.match(resultAction)) {
        // Email exists, proceed to send the verification code
        await handleSendVerificationCode(); // Call the function to send verification code
      } else {
        toast.error(resultAction.payload || "Email n'existe pas ou n'est pas encore confirmé.", {
          position: 'top-right',
          autoClose: 3000,
          theme: 'dark',
        });
       handleClose(); // Close the modal
      }
    } catch (error) {
      toast.error('Failed to verify email. Please try again.', {
        position: 'top-right',
        autoClose: 3000,
        theme: 'dark',
      });
    }
  };

  const handleSendVerificationCode = async () => {
    try {
      // Dispatch the sendVerificationCode action
      await dispatch(sendVerificationCode({ email }));

      toast.success('Verification code sent successfully!', {
        position: 'top-right',
        autoClose: 3000,
        theme: 'dark',
      });
      setVerificationOpen(true); 
      setOpen(false); 
    } catch (error) {
      toast.error('Failed to send verification code. Please try again.', {
        position: 'top-right',
        autoClose: 3000,
        theme: 'dark',
      });
    }
  };

  return (
    <Box sx={{ textAlign: 'center' }}>
      <ToastContainer />
      <Dialog
        open={open}
        onClose={handleClose}
        sx={{
          '& .MuiDialog-paper': {
            backgroundColor: '#3c3c3c',
            color: '#A68E5D',
            borderRadius: 2,
            maxWidth: '400px',
            width: '100%',
            padding: '20px',
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)',
            textAlign: 'center',
          },
          '& .MuiBackdrop-root': {
            backdropFilter: 'blur(4px)',
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
          },
        }}
      >
        <DialogTitle
          sx={{
            color: '#A68E5D',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            fontSize: '1.5rem',
            textAlign: 'center',
          }}
        >
          Reset Password
        </DialogTitle>
        <DialogContent>
          <TextField
            label="Email"
            variant="outlined"
            fullWidth
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            InputLabelProps={{
              style: { color: 'white' },
            }}
            InputProps={{
              style: {
                backgroundColor: 'transparent',
                color: 'white',
                borderRadius: '15px',
              },
            }}
            sx={{
              mb: 2,
              '& .MuiOutlinedInput-root': {
                '& fieldset': { borderColor: 'white' },
                '&:hover fieldset': { borderColor: 'white' },
                '&.Mui-focused fieldset': { borderColor: 'white' },
              },
              '& .MuiInputLabel-outlined.Mui-focused': {
                color: 'white',
              },
            }}
          />
          <Button
            variant="contained"
            fullWidth
            onClick={handleVerifyEmail} // Call the verification function
            sx={{
              backgroundColor: 'grey',
              color: 'white',
              mt: 2,
              padding: '10px',
              fontSize: '1rem',
              '&:hover': { backgroundColor: 'transparent' },
            }}
          >
            Send Verification Code
          </Button>
        </DialogContent>
      </Dialog>

      {verificationOpen && (
        <VerificationCodeModal onClose={() => setVerificationOpen(false)} />
      )}
    </Box>
  );
};

export default ResetPasswordModal;