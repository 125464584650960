import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Grid,
  Paper,
  IconButton,
  TextField,
  Button,
  CircularProgress,
  useMediaQuery
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import MapOutlinedIcon from '@mui/icons-material/MapOutlined';
import MessageIcon from '@mui/icons-material/Message';
import SendIcon from '@mui/icons-material/Send';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import emailjs from 'emailjs-com';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchObjetById } from '../store/objet';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { ToastContainer, toast } from 'react-toastify';

//Store
import { sendContactVendorEmail } from "../store/mailer";
const Product = () => {
  const { objetID } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { selectedItem: objet, status, error } = useSelector((state) => state.objet);
  const userInfo = useSelector((state) => state.auth.userInfo)
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState('');
  const [sending, setSending] = useState(false);
  const [success, setSuccess] = useState('');
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1300);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (objetID) {
      dispatch(fetchObjetById(objetID));
    }
  }, [dispatch, objetID]);

  const handleBackClick = () => {
    navigate(-1);;
  };

  const handleSendEmail = () => {
    //Vendor info
    let { utilisateur, nom: productName, } = objet
    // Customer info 
    console.log(userInfo, "vuserInfo");

    let { email: clientEmail, nom: clientLastName, prenom: clientFirstName, numeroTelephone: clientPhoneNumber } = userInfo;
    let body = {
      to: utilisateur.email,
      clientName: `${clientFirstName} ${clientLastName}`,
      clientEmail: clientEmail,
      productName: productName,
      clientPhoneNumber: clientPhoneNumber,
    }
    dispatch(sendContactVendorEmail(body))
      .then((response) => {
        if (response.meta.requestStatus === "fulfilled") {
          toast.success("E-mail envoyé au vendeur avec succès", { theme: "dark" });
        } else {
          const errorMessage = response.payload?.message || "Erreur lors de la connexion.";
          toast.error(errorMessage, { theme: "dark" });
        }
      })
      .catch((error) => {
        toast.error(error.message || "Une erreur inattendue est survenue.", { theme: "dark" });
      });
  };
  const countryCodes = {
    Luxembourg: '+352',
    Liechtenstein: '+423',
    Suisse: '+41',
    Belgique: '+32',
    France: '+33',
  };

  const userCountry = objet?.pays.nom;  // Get the country from the user object
  const countryCode = countryCodes[userCountry]

  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',

          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          backgroundColor: '#232323',
        }}
      >
        <CircularProgress sx={{ color: '#A68E5D' }} />
      </Box>
    );
  }

  return (
    <Box
      sx={{

        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: '#232323',
        color: '#D4B382',
        minHeight: '100vh',
        padding: isMobile ? 0 : '2rem',
        fontFamily: 'Arial, sans-serif',
      }}
    >
      <Grid container sx={{ width: isMobile ? "90%" : 5 / 6 }}>

        <Grid container spacing={4} sx={{ paddingY: '2rem', color: '#D4B382' }}>
          {/* Artwork Details Section */}
          <Grid item xs={12} md={6} sx={{ textAlign: 'left', }}>

            <Box onClick={handleBackClick} sx={{ display: "flex", flexDirection: "row", mb: isMobile ? 2 : 15 }}>
              <ArrowBackIcon />
              <Typography variant="body1" sx={{ ml: 1, fontFamily: "Poppins, sans-serif", }}>
                Retour
              </Typography>
            </Box>
            <Typography variant="h5" sx={{ color: '#D4B382', fontWeight: 700, fontFamily: "Georgia, sans-serif", fontSize: "2rem", mb: 2 }}>
              {objet?.nom}
            </Typography>
            <Typography variant="h6" sx={{ color: '#D4B382', fontWeight: 500, fontWeight: 300, fontFamily: "Georgia, sans-serif", fontSize: "1.2rem", mb: 2 }}>
              {objet?.utilisateur?.nom} {objet?.utilisateur?.prenom}
            </Typography>
            <Typography variant="body2" sx={{ mb: 2, color: '#DBDBDB', lineHeight: 1.5, fontWeight: 400, fontFamily: "Poppins, sans-serif", fontSize: "0.8rem", width: isMobile ? "100%" : 3 / 5 }}>
              {objet?.description}
            </Typography>
            <hr style={{ width: isMobile ? "100%" : "80%", border: "0.5px solid #65635F99", margin: "16px 0" }} />
            <Typography variant="h5" sx={{ mt: 2, color: '#FFFFFF', fontWeight: 'bold' }}>
              {objet?.prix} 
            </Typography>
            <Typography variant="body2" sx={{ display: "flex", flexDirection: "row", justifyContent: "left", alignItems: "center", mt: 1, color: '#DBDBDB', fontWeight: 700, fontFamily: "Poppins, sans-serif", fontSize: "1rem", }}>
              <LocationOnIcon sx={{ fontSize: 15, color: "#DBDBDB" }} ></LocationOnIcon>
              {objet?.pays ? objet?.pays?.nom : "Unknown Country"}
            </Typography>
          </Grid>

          {/* Contact Section */}
          <Grid item xs={12} md={6} sx={{ mt: {}, position: "relative", }}>
            <Typography variant="h6" sx={{ mb: 3, color: '#D4B382', fontWeight: 'bold', fontWeight: 700, fontFamily: "Georgia, sans-serif", fontSize: "1.5rem" }}>
              Contacter le vendeur
            </Typography>


            <Paper
              elevation={4}
              sx={{
                padding: '1rem',
                backgroundColor: '#484848',
                borderRadius: '50px',
                display: 'flex',
                alignItems: 'center',
                mb: 2,
                color: '#fff',
                boxShadow: '0px 4px 8px rgba(0,0,0,0.2)',
              }}
            >
              <Box sx={{ backgroundColor: "#333333", display: "flex", alignItems: "center", justifyContent: "center", width: "3.5rem", height: "3rem", borderRadius: "45%", mr: 2 }}>
                <LocalPhoneOutlinedIcon sx={{ color: '#FFFFFF' }} />
              </Box>
              <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "start" }}>
                <Typography variant="body1" sx={{ fontWeight: 400, fontFamily: "Poppins, sans-serif", fontSize: "1rem" }}>
                  Téléphone
                </Typography>
                <Typography variant="body1" sx={{ fontWeight: 100, fontFamily: "Poppins, sans-serif", fontSize: "0.9rem" }}>
                  {countryCode} {objet?.utilisateur?.numeroTelephone}
                </Typography>
              </Box>

            </Paper>

            <Paper
              elevation={4}
              sx={{
                position: "relative",
                padding: '1rem',
                backgroundColor: '#484848',
                borderRadius: '50px',
                display: 'flex',
                alignItems: 'center',
                mb: 2,
                color: '#fff',
                boxShadow: '0px 4px 8px rgba(0,0,0,0.2)',
                zIndex: 2
              }}
            >
              <Box sx={{ backgroundColor: "#333333", display: "flex", alignItems: "center", justifyContent: "center", width: "3.5rem", height: "3rem", borderRadius: "45%", mr: 2 }}>
                <MapOutlinedIcon sx={{ color: '#FFFFFF' }} />
              </Box>
              <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "start" }}>
                <Typography variant="body1" sx={{ fontWeight: 400, fontFamily: "Poppins, sans-serif", fontSize: "1rem" }}>Message</Typography>
                <Typography variant="body1" sx={{ fontWeight: 100, fontFamily: "Poppins, sans-serif", fontSize: "0.9rem" }}>Utilisez notre message pré-rédigé</Typography>
              </Box>

            </Paper>
            <Box sx={{ position: "relative", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "end", py: 2, mt: -7, border: '3px solid #65635F99', height: isMobile ? "12rem" : "9rem", zIndex: 1 }}>
              <Typography variant="body1" sx={{ fontWeight: 100, fontFamily: "Poppins, sans-serif", fontSize: "0.7rem", color: "#AFAFAF", textAlign: "left", px: 3 }}>Je suis intéressé par l'achat de votre produit "{objet?.nom}" répertorié sur L'ART ENTRE NOUS. Pourriez-vous fournir plus de détails ou répondre à quelques questions que j'ai sur l'article ? J'aimerais également discuter des options de paiement et d'expédition.</Typography>
              <Button
                variant="contained"
                // fullWidth
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  mt: 2,
                  backgroundColor: '#484848',
                  color: '#FFFFFF',
                  fontWeight: 'bold',
                  borderRadius: '20px',
                  '&:hover': { backgroundColor: '#b3946e' },
                }}
                endIcon={
                  sending ? (
                    <CircularProgress size={20} sx={{ color: '#121212' }} />
                  ) : (
                    <SendOutlinedIcon />
                  )
                }
                onClick={handleSendEmail}
                disabled={sending}
              >
                {sending ? 'envoyer...' : 'envoyer'}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Box >
  );
};

export default Product;
