import React, { useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SetNewPasswordModal from './newPassword';
import { useSelector } from 'react-redux'; 
import { useDispatch } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



const VerificationCodeModal = ({ onClose }) => {
  const [open, setOpen] = useState(true);
  const [setPasswordOpen, setSetPasswordOpen] = useState(false);
  const [code, setCode] = useState('');
  const storedVerificationCode = useSelector((state) => state.auth.verificationCode); // Get stored code from Redux
  const dispatch = useDispatch(); // Import dispatch if needed
  
  const handleClose = () => {
    setOpen(false);
    if (onClose) onClose();
  };
  
  const handleVerifyCode = () => {
    console.log(storedVerificationCode,"VerificationCode");
    if (storedVerificationCode === null) {
      toast.error("Aucun code de vérification disponible.", {
        position: 'top-right',
        autoClose: 3000,
        theme: 'dark',
      });
      return;
    }
  
    if (code === storedVerificationCode.toString()) { // Compare the entered code with stored code
      setSetPasswordOpen(true); // Open SetNewPasswordModal
      setOpen(false); // Close VerificationCodeModal
    } else {
      toast.error("Le code est incorrect.", {
        position: 'top-right',
        autoClose: 3000,
        theme: 'dark',
      });
    }
  };
  

  return (
    <Box sx={{ textAlign: 'center' }}>
      <Dialog
        open={open}
        onClose={handleClose}
        sx={{
          '& .MuiDialog-paper': {
            backgroundColor: '#3c3c3c',
            color: '#A68E5D',
            borderRadius: 2,
            maxWidth: '400px',
            width: '100%',
            padding: '20px',
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)',
            textAlign: 'center',
            position: 'relative',
          },
          '& .MuiBackdrop-root': {
            backdropFilter: 'blur(4px)',
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
          },
        }}
      >
        <IconButton
          onClick={handleClose}
          sx={{ position: 'absolute', top: 10, right: 10, color: '#A68E5D' }}
        >
          <CloseIcon />
        </IconButton>

        <DialogTitle
          sx={{
            color: '#A68E5D',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            fontSize: '1.5rem',
            textAlign: 'center',
          }}
        >
          Enter Verification Code
        </DialogTitle>

        <DialogContent>
          <TextField
            label="Verification Code"
            variant="outlined"
            fullWidth
            value={code}
            onChange={(e) => setCode(e.target.value)}
            InputLabelProps={{ style: { color: 'white' } }}
            InputProps={{
              style: {
                backgroundColor: 'transparent',
                color: 'white',
                borderRadius: '15px',
              },
            }}
            sx={{
              mb: 2,
              '& .MuiOutlinedInput-root': {
                '& fieldset': { borderColor: 'white' },
                '&:hover fieldset': { borderColor: 'white' },
                '&.Mui-focused fieldset': { borderColor: 'white' },
              },
              '& .MuiInputLabel-outlined.Mui-focused': {
                color: 'white',
              },
            }}
          />
          <Button
            variant="contained"
            fullWidth
            onClick={handleVerifyCode}
            sx={{
              backgroundColor: 'grey',
              color: 'white',
              mt: 2,
              padding: '10px',
              fontSize: '1rem',
              '&:hover': { backgroundColor: 'transparent' },
            }}
          >
            Verify Code
          </Button>
        </DialogContent>
      </Dialog>

      {setPasswordOpen && (
        <SetNewPasswordModal onClose={() => setSetPasswordOpen(false)} />
      )}
    </Box>
  );
};

export default VerificationCodeModal;