import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  Grid,
  Paper,
  IconButton,
  Dialog,
  Divider,
  useMediaQuery
} from "@mui/material";
import { useTheme } from '@mui/material/styles';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import RoomIcon from "@mui/icons-material/Room";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchObjetById } from "../store/objet";
import { urlFor } from "../configs/sanityClient";

const constantImageUrl =
  "https://static.vecteezy.com/system/resources/thumbnails/035/147/887/small/ai-generated-antique-armchair-throne-on-transparent-background-image-png.png";
const getImageUrl = (image) => {
  try {
    const assetRef = image.file?.asset?._ref;
    if (assetRef) {
      return urlFor(image.file).url();
    } else {
      return image.path;
    }
  } catch (error) {
    console.error("Error building Sanity image URL:", error);
  }
  return constantImageUrl;
};
const ProductPage = () => {
  const { productId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    selectedItem: product,
    status,
    error,
  } = useSelector((state) => state.objet);
  const [mainImage, setMainImage] = useState("");
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    if (productId) {
      dispatch(fetchObjetById(productId));
    }
  }, [dispatch, productId]);

  useEffect(() => {
    if (product && product?.images && product?.images.length > 0) {
      setMainImage(getImageUrl(product.images[0]));
    }
  }, [product]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleBackClick = () => {
    navigate(-1);
  };

  const handleBuyClick = () => {
    navigate(`/checkout?objetID=${productId}`);
  };

  const handleThumbnailClick = (image) => {
    setMainImage(getImageUrl(image));
  };

  const handleViewInRoomClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  console.log(product,'product');
  
  if (status === "loading") {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Typography sx={{ color: "#A68E5D", fontSize: "20px" }}>
          Loading...
        </Typography>
      </Box>
    );
  }

  if (status === "failed") {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Typography sx={{ color: "#FF6B6B", fontSize: "20px" }}>
          Error: {error}
        </Typography>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#232323",
        color: "#A68E5D",
        padding: "40px",
      }}
    >
      {product && (
        <Grid
          container
          spacing={4}
          sx={{
            flexGrow: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          {/* Images Container */}
          <Grid
            item
            xs={12}
            md={6}
            sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
          >
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              {product?.images && product?.images?.length > 0 ? (
                product?.images?.map((image, index) => (
                  <img
                    key={index}
                    src={getImageUrl(image) || image.path}
                    alt={`Thumbnail ${index + 1} of artwork`}
                    width={60}
                    height={60}
                    style={{
                      borderRadius: "5px",
                      marginBottom: "10px",
                      cursor: "pointer",
                      border:
                        mainImage === getImageUrl(image)
                          ? "1px solid #A68E5D"
                          : "1px solid transparent",
                    }}
                    onClick={() => handleThumbnailClick(image)}
                  />
                ))
              ) : (
                <Typography variant="body2" sx={{ color: "grey" }}>
                  No images available
                </Typography>
              )}
            </Box>

            <Box
              elevation={4}
              sx={{
                padding: "10px",
                backgroundColor: "#232323",
                borderRadius: "20px",
                textAlign: "center",
                width: "100%",
                maxWidth: "450px",
              }}
            >
              <img
                src={mainImage}
                alt="Main artwork display"
                style={{ width: "100%", height: "auto" }}
              />
              <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
                <IconButton
                  sx={{ color: "#A68E5D", fontSize: "14px" }}
                  onClick={handleViewInRoomClick}
                >
                  <RoomIcon fontSize="small" />
                  <Typography variant="body2" sx={{ ml: 1 }}>
  Voir dans une pièce
</Typography>

                </IconButton>
              </Box>
            </Box>
          </Grid>
          {/* Images Container */}
          <Grid
            item
            xs={12}
            md={4}
            sx={{
              paddingLeft: { md: "40px" },
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
           <Box 
  onClick={handleBackClick} 
  sx={{ 
    display: "flex", 
    flexDirection: "row", 
    mb: isMobile ? 2 : 2, 
    cursor: "pointer" 
  }}
>
  <ArrowBackIcon />
  <Typography variant="body1" sx={{ ml: 1, fontFamily: "Poppins, sans-serif" }}>
    Retour
  </Typography>
</Box>


            <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
  <Typography
    variant="h5"
    sx={{ color: '#A68E5D', fontWeight: 400, fontFamily: "Georgia, sans-serif", fontSize: "2rem", mr: 2 }}
  >
    {product.nom}
  </Typography>
  <Typography
    variant="h6"
    sx={{ color: '#DBDBDB', fontWeight: 300, fontFamily: "Poppins, sans-serif", fontSize: "1rem" }}
  >
    {product.dateCreated} 
  </Typography>
</Box>

            <Typography
              variant="subtitle1"
              sx={{ color: '#A68E5D', fontWeight: 100, fontFamily: "Georgia, sans-serif", fontSize: "1.4rem", mb: 2 }}
            >
              {product.categorie?.nom}
            </Typography>
            
            <Typography
              variant="body1"
              sx={{ color: '#DBDBDB', fontWeight: 100, fontFamily: "Poppins, sans-serif", fontSize: "0.8rem", mb: 1, width: 4 / 5, textAlign: "left" }}
            >
              {product.description}
              </Typography>

            <Divider sx={{ backgroundColor: "grey", my: 1, width: "100%" }} />

            <Typography
              variant="h6"
              sx={{
                mt: 1,
                color: "white",
                fontSize: "1.5rem",
                fontFamily: "Poppins, sans-serif",
                fontWeight: 100
              }}
            >
 {product.prix}
</Typography>
            <Box
              variant="body2"
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                mt: 1,
                color: "#C0C0C0",
                fontSize: "1rem",
                fontFamily: "Poppins, sans-serif",
                fontWeight: 100
              }}
            >
<RoomIcon fontSize="small" sx={{ fontSize: 20 }} /> Expédié depuis {product.pays?.nom} 
</Box>
            <Button
              onClick={handleBuyClick}
              variant="contained"
              sx={{
                mt: 4,
                backgroundColor: "#A68E5D",
                color: "#232323",
                fontWeight: "bold",
                textTransform: "none",
                "&:hover": { backgroundColor: "grey", color: "#A68E5D" },
              }}
            >
              Acheter Maintenant
            </Button>
          </Grid>
        </Grid>
      )}

      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "black",
          }}
        >
          <img src={mainImage} alt="Zoomed artwork" style={{ width: "100%", maxWidth: "800px" }} />
        </Box>
      </Dialog>
    </Box>
  );
};

export default ProductPage;
